import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import BodyParser from "./StrapiParser";
import { IStrapiProps } from "./StrapiParser/types";

import GenericHeroSection from "../sections/GenericHero/GenericHero";
import Seoinfo from "./seoinfo/seoinfo";
import seoImage from "../../static/seo/seo_image.png";
// assets
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Layout from "./layout";
import { transformToSlug } from "./utils/transformToSlug";

const ArticleTemplate = ({ data }: any) => {
  const [isClient, setIsClient] = useState(false);
  const Article = data?.cms?.blogArticle?.data?.attributes;

  useEffect(() => {
    setIsClient(true);
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isClient) return <></>;

  return (
    <main>
      <Layout>
        <Seoinfo
          siteName={Article?.SEO?.Title || ""}
          titlePage={Article?.SEO?.Title || ""}
          description={Article?.SEO?.Description || ""}
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url={`/blog/${transformToSlug(Article.Article_Title)}`}
          imageFacebook={seoImage}
          canonical={Article?.SEO?.Is_Canonical}
        />
        <div style={{ marginBottom: "20px" }}>
          <GenericHeroSection
            title={Article?.Article_Title}
            description={Article?.Article_Subtitle}
            image={Article?.Article_Thumbnail}
          />
          {Article?.Body &&
            Article?.Body.map((strapiComponent: IStrapiProps["component"]) => (
              <BodyParser
                key={strapiComponent?.id}
                component={strapiComponent}
              />
            ))}
        </div>
      </Layout>
    </main>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    cms {
      blogArticle(id: $id) {
        data {
          attributes {
            Article_Subtitle
            Article_Title
            Content_Tag
            SEO {
              Description
              Image {
                ...Media
              }
              Title
              Is_Canonical
            }
            Article_Thumbnail {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            Main_Page_Thumb {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            Body {
              ...ParagraphFeatureSection
              ...Paragraph
              ...ParagraphHighlight
              ...Subtitle
            }
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
